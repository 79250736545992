import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rb-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Input() menu: INavbarItem[] = [];
  @Input() isExpanded: boolean = true;


  constructor() { }

  ngOnInit(): void {
  }



}

export interface INavbarItem {
  title: string;
  onclick?: () => {};
  routerLink?: string;
  href?: string;
  class?: string;
  isActive?: boolean;
  submenu?: INavbarItem[];
}
