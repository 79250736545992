import { AfterViewInit, Component, Directive, Input, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { TemplateIdDirective } from '../../directives/template-id.directive';


@Component({
  selector: 'rb-master-page',
  templateUrl: './master-page.component.html',
  styleUrls: ['./master-page.component.scss']
})
export class MasterPageComponent implements OnInit, AfterViewInit {

  @ViewChildren(TemplateIdDirective) templates!: QueryList<TemplateIdDirective>;


  sidebarItems: {
    title: string;
    icon?: TemplateRef<any>
    path: string;
  }[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.initSidebarItems();
  }

  private initSidebarItems(): void {
    this.sidebarItems = [
      {
        title: 'KYC',
        icon: this.templates.find((d) => d.templateId === 'kyc')?.templateRef,
        path: '/kyc',
      },
      {
        title: 'Bolt',
        icon: this.templates.find((d) => d.templateId === 'bolt')?.templateRef,
        path: '/bolt',
      },
      {
        title: 'Heart',
        icon: this.templates.find((d) => d.templateId === 'heart')?.templateRef,
        path: '/heart',
      },
      {
        title: 'People',
        icon: this.templates.find((d) => d.templateId === 'people')?.templateRef,
        path: '/people',
      },
      {
        title: 'Reload',
        icon: this.templates.find((d) => d.templateId === 'reload')?.templateRef,
        path: '/reload',
      },
      {
        title: 'Tasks',
        icon: this.templates.find((d) => d.templateId === 'tasks')?.templateRef,
        path: '/tasks',
      },
      {
        title: 'Eye',
        icon: this.templates.find((d) => d.templateId === 'eye')?.templateRef,
        path: '/eye',
      },
      {
        title: 'Chart',
        icon: this.templates.find((d) => d.templateId === 'chart')?.templateRef,
        path: '/chart',
      },
      {
        title: 'Read',
        icon: this.templates.find((d) => d.templateId === 'read')?.templateRef,
        path: '/read',
      },
      {
        title: 'Wallet',
        icon: this.templates.find((d) => d.templateId === 'wallet')?.templateRef,
        path: '/wallet',
      },
      {
        title: 'Student',
        icon: this.templates.find((d) => d.templateId === 'student')?.templateRef,
        path: '/student',
      },
      {
        title: 'Help',
        icon: this.templates.find((d) => d.templateId === 'help')?.templateRef,
        path: '/help',
      },
    ];
  }

}
