import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    const headers: any = {
      'secret-key': environment.ams_secret_key,
      'api-key': environment.ams_api_key,
      AUTHORIZATION: `Token ${token}`,
    };
    for (const key of request.headers.keys()) {
      headers[key] = request.headers.get(key);
    }
    const reqOptions = {
      headers: new HttpHeaders({
        ...headers,
      })
    };
    return next.handle(request);
  }
}
